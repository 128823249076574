import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins


export default {
  name: 'bar-chart',
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options', 'height'],
  mounted () {
    var options = this.options;
    options.legend = {display: false};
    options.plugins = {
        legend: {
            display: false,
        },
    }
    options.scales = {
        xAxes: [{
            display: true,
            ticks: {
                display: true
            }
        }],
        yAxes: [{
            display: true,
            ticks: {
                beginAtZero: true,
                min: 0,
                max: 100
            }
        }]
    };

    this.renderChart(this.chartData, options)
  },
}