<template>
    <section :class="{'home-section': !options || !options.cardView}">
        <section-title :title="title" v-if="!options || !options.cardView">
        </section-title>
        <div class="home-content border-bottom font-weight-bold" v-else>
            Flight Data Precision
        </div>

        <section class="bg-mix">
            <report-window
                endpoint="/reports/flight_data_precision"
                v-if="ready"
                :perPage="50"
                :fields="fields"
                :title="title"
                :headers="headers"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
                :columnsClasses="columnsClasses"
                :allowChart="true"
                :cardView="options && options.cardView"
                :chartOptions="chartOptions"
                :showDefaultChart="true"
                :runMounted="true"
                ref="reportWindow"
                @afterEdit="afterEdit"
            ></report-window>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
const colors = ['#EC7063', '#45B39D', '#F4D03F', '#85C1E9', '#BB8FCE', '#E5E7E9', '#2C3E50', '#FDFEFE']
export default {
    name: "market-summary-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
    },
     mounted: async function() {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: 'FromDate',
                    editor: 'date',
                    label: 'From Date',
                    defValue: moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
                    //required: true,
                },
                {
                    name: 'ToDate',
                    editor: 'date',
                    label: 'To Date',
                    defValue: moment(new Date()).format('YYYY-MM-DD'),
                    //required: true,
                },
                {
                    name: 'Market',
                    label: 'Market',
                }
            ],
            columnsClasses: {
            },
            title: "Flight Data Precision",
            headers: {
            },
            templates: [

            ],
            columnClick: {
            },
            fieldClasses: {
            },
            chartOptions: [
                {
                    y: 'year_month',
                    x: ['prec'],
                    colors: [colors[3]],
                    scales: {
                        yAxes: {
                            min: 0,
                            max: 100
                        }
                    }
                },
            ]
        };
    },
    methods: {
        afterEdit (fieldName) {
            this.$refs.reportWindow.run();
        }
    }
};
</script>

<style scoped>
.home-content {
    height: 45px !important;
}
</style>