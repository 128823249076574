import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Router from '@/router/router';
import store from '@/store/store';
const login = importVueComp('components/tools', 'Login');
const home = importVueComp('components/pages', 'Home');
const healthStatus = importVueComp('components/pages', 'HealthStatus');
import modules from '@/tools/modules';
const abmTable = importVueComp('components/pages', 'AbmTable');
const accessGroupAbm = importVueComp('components/pages', 'AbmAccessGroup');
const accessGroupWindow = importVueComp('components/pages', 'AccessGroupWindow');
const recordWindow = importVueComp('components/tools', 'RecordWindow');
import flightDataReport from '@/components/reports/FlightDataReport';
import toProcessReport from '@/components/reports/ToProcessReport';
import recDataReport from '@/components/reports/RecDataReport';
import recSummaryReport from '@/components/reports/RecSummaryReport';
import lidDataReport from '@/components/reports/LidDataReport';
import lidControlReport from '@/components/reports/LidControlReport';
import marketsSummary from '@/components/reports/MarketsSummary';
import changePassword from '@/components/pages/ChangePassword';
import settings from '@/components/pages/Settings';
import segments from '@/components/pages/Segments';
import rules from '@/components/pages/Rules';
import routes from '@/components/pages/Routes';
import operations from '@/components/pages/Operations';
import tableauReport1 from '@/components/reports/TableauReport1';
import tableauReport2 from '@/components/reports/TableauReport2';
import flightDataPrecision from '@/components/reports/FlightDataPrecision';

Vue.use(Router);

const router = new Router({
    routes: [
        { path: '*', redirect: '/' },
        { path: '/', name: 'home', component: home, props: true },
        { path: '/home', component: home, props: true },
        { path: '/login', component: login },
        { path: '/health-status', name: 'health-status', component: healthStatus},
        { path: '/abm/access-group', name: 'abm-access-group', component: accessGroupAbm, props: {table: 'access_group'}},
        { path: '/abm/access-group/:id?', name: 'access-group-window', component: accessGroupWindow, props: true},
        { path: '/abm/:table', name: 'abm', component: abmTable, props: true},
        { path: '/abm/:table/:id?', name: 'record', component: recordWindow, props: true},
        { path: '/past-data', name: 'past-data', component: flightDataReport, label: 'Past Data'},
        { path: '/flight-data-precision', name: 'flight-data-precision', component: flightDataPrecision, label: 'Flight Data Precision'},
        { path: '/to-process', name: 'to-process', component: toProcessReport, label: 'To Process'},
        { path: '/rec-data', name: 'rec-data', component: recDataReport, label: 'Recommendations'},
        { path: '/rec-summary', name: 'rec-summary', component: recSummaryReport, label: 'Recommendations Summary'},
        { path: '/lid-data', name: 'lid-data', component: lidDataReport, label: 'Lid Data'},
        { path: '/lid-control', name: 'lid-control', component: lidControlReport, label: 'Lid Control', props: true},
        { path: '/markets-summary', name: 'markets-summary', component: marketsSummary, label: 'Markets Summary', props: true},
        { path: '/change-password', name: 'change-password', component: changePassword },
        { path: '/settings', name: 'settings', component: settings, label: 'Settings'},
        { path: '/segments', name: 'segments', component: segments, label: 'Segments'},
        { path: '/rules', name: 'rules', component: rules},
        { path: '/routes', name: 'routes', component: routes},
        { path: '/operations', name: 'operations', component: operations},
        { path: '/tableau-report1', name: 'tableau-report1', component: tableauReport1, label: 'tableau-report1'},
        { path: '/tableau-report2', name: 'tableau-report2', component: tableauReport2, label: 'tableau-report2'},
    ]
});


function setRouteHistory (path) {
    let p = path.split('/');
    if (!parseInt(p[p.length-1])) return;

    let routerHistory = localStorage.getItem('router-history');
    if (!routerHistory) {
        routerHistory = JSON.stringify([]);
    }
    routerHistory = JSON.parse(routerHistory);
    let f = _.find(routerHistory, (r) => r.path == path);
    if (!f) {
        if (routerHistory.length>=21) {
            routerHistory.splice(0, 1)
        }
        routerHistory.push({path, name: ''});
        localStorage.setItem('router-history', JSON.stringify(routerHistory));
    }
}

router.beforeEach(async function(to, from, next) {
    let changePassword = '/change-password';
    if (store.getters.modified && to.path != from.path) {
        //next(from.path);
        store.commit('setCallConfirmClose', true);
        store.commit('setToPath', to.path);
        return;
    }
    let isPublic = false;
    if (to.path.split('/')[1]=='public') {
        isPublic = true;
    }
    if (to.path == '/login' || isPublic) {
        next();
        return;
    } else {
        let currentUser = store.state.main.me && store.state.main.me.user_id;
        if (!currentUser) {
            let me = await api.getCurrentUser();
            if (!me) {
                next('/login');
                return;
            } else {
                store.commit('setMe', me);
                Sentry.setUser({"name": me.user_name});
                if (me && me.change_password && to.path != changePassword) {
                    next(changePassword);
                    return;
                }
            }
        } else if (currentUser && store.state.main.me.change_password && to.path != changePassword) {
            next(changePassword);
            return;
        }
        if (to.path==changePassword) {
            next();
            return;
        } else if (to.path=='/') {
            next();
            return;
        } else if (tools.canAccess(store.state.main.me, 'view', 'GET', to.name)) {
            let route = _.find(router.options.routes, (r) => r.name == to.name)
            if (route && route.menu) {
                if (!tools.canAccess(store.state.main.me, 'view','GET', '/' + modules[route.menu] + '/')) {
                    next('/');
                    return;
                }
            }
            next();
            return;
        } else {
            next('/');
            return;
        }
    }
});

router.afterEach((to, from) => {
    setRouteHistory(to.path)
})

export default router;
